




















































































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api';
import OriginPicker from './StopPicker.vue';
import DestinationPicker from './StopPicker.vue';
import DepartureDate from './DepartureDate.vue';
import ReturnDate from './ReturnDate.vue';
import PassengerInput from './PassengerInput.vue';
//import SsrListInput from './SsrListInput.vue';
import WheelchairInput from './WheelchairInput.vue';
//import WheelchairClassSelect from './WheelchairClassSelect.vue';
import AdditionalRequiredInput from './additionalLuggageRadioComponent.vue';
import IconChangeOfDirection from '../assets/icons/iconChangeOfDirection.vue';
import Store from '../store/store';
import { Extra, SsrType } from "../interface/types";

export default defineComponent({
  components: {
    OriginPicker,
    DestinationPicker,
    DepartureDate,
    IconChangeOfDirection,
    ReturnDate,
    PassengerInput,
    WheelchairInput,
    //WheelchairClassSelect,
    //SsrListInput,
    AdditionalRequiredInput,
  },

  setup() {
      
    onMounted(() => {
      Store.getFareData();
      Store.getSsrTypesData();
    });

    const origin = ref(null);
    const destination = ref(null);
    const Progress = ref(0);

    const isValidSearch = ref(false);
    const openComponent = ref('');
    const theStore = Store;
    const findButtonDisabled = ref<boolean>(true);

    const showSelectClass = computed(() => Store.state.wheelchairRequired && hasMultiplePassengerGroups());
    const wheelchairRequired = ref<boolean>(Store.state.wheelchairRequired);
    const wheelchairListDisabled = ref<boolean>(true);
    const ssrListDisabled = ref<boolean>(true);
    const showSecondLine = computed(() => {
        if (Store.state.origin.name != '' && Store.state.origin.code != '' &&
        Store.state.destination.name != '' && Store.state.destination.code != '' && Store.state.departure.date != '')
          return true;
        return false;
    });

    const numberOfPassTypes = computed(() => {
      let num = 0;
      if (Store.state.passengers) {
        Store.state.passengers?.forEach(pass => {
          if(pass.quantity > 0){
            num++;
          }
        })
      }
        
       return num;
    });



    // change button text if searching/adding luggage
    const findButtonText = computed (() => {
      if (Store.appSwitches.needsAdditionalStorage)
        return 'Add Hold Items'
      else
        return 'Find Journeys';
    });
    // change icon to ticket or luggage if searching/adding luggage
    const isSearching = computed (() => {
      if (Store.appSwitches.needsAdditionalStorage)
        return false
      else
        return true;
    });

    //Set Store ssr For submit once next step pressed
    let storeExtras : Extra[] =  [];
    let storeSSRExtras : Extra[] =  [];

    let wheelchairClassSelected = ref<boolean>(true);

    const wheelchairRadioValue = computed(()=>{
      if (Store.state.wheelchairRequired)
        return 1
      else
        return 2;
    });

    const additionalRadioValue = computed(()=>{
      if (Store.appSwitches.needsAdditionalStorage)
        return 10
      else
        return 11;
    });

    const updateKey = ref(1);
    const updateKey2 = ref(10);
    const updateKey3 = ref(50);

    const bikeDropKey = ref(1000);
    const wheelchairDropKey = ref(2000);

    //ek - monitor all fields and display clear button when any change
    const isScreenDirty = computed(() => {

      //have we got passengers
      if (Store.state.passengers != undefined) {
        Store.state.passengers.forEach((pass) => {
          if (pass.quantity > 0)
            return true;
        })
      }

      if (Store.state.origin.code  != '') return true;
      if (Store.state.destination.code  != '') return true;
      if (Store.state.departure.date  != '') return true;
      return false;
    });

    // Returns true if more than one type of passenger is selected
    // if this is the case, n extra prompt needs to be made to the
    // user to assign the wheelchair to one of these types
    function hasMultiplePassengerGroups() {
      let singlePassType = false;
      let multiplePassType = false;
      Store.state.passengers?.forEach(pass => {
        if (pass.quantity > 0) {
          if (!singlePassType) {
            singlePassType = true;
          } else {
            multiplePassType = true;
          }
        }
      });
      //updateKey.value++;
      return multiplePassType;
    }

    
    //called on emit from radio - assigns or de-assigns wheelchair to a fare class if only one selected
    //otherwise unhides the selection box for the user to pick
    function assignWheelchair(onOff : boolean) {
      updateKey.value++;
      Store.state.wheelchairRequired = onOff;
      wheelchairRequired.value = onOff;
      if (onOff) {
        if (hasMultiplePassengerGroups()) {
            wheelchairClassSelected.value = false;
            //showSelectClass.value = true;
            findButtonDisabled.value = true;
        } else {
          wheelchairClassSelected.value = true;
          //showSelectClass.value = false;
          Store.state.passengers?.forEach(pass => {
            if (pass.quantity > 0) {
              Store.state.wheelchairFareclass = pass.fareClass;
            }
          })
        }
      } else {
          wheelchairClassSelected.value = false;
          //showSelectClass.value = false;        
          Store.state.wheelchairFareclass = '';
      }
    }

    function clearItems(){
      Store.resetSearchParameters();
      Progress.value = 0;
      isValidSearch.value = false;
      wheelchairRequired.value = false;
      wheelchairListDisabled.value = true;
       updateKey.value ++;
       updateKey2.value ++;
       wheelchairDropKey.value++;
       return true;
    }

    function clearReturn() {
      Store.resetReturnJourney();
    }

    function forceIt() {
      Progress.value = setProgress();
      updateKey3.value++;
    }

    function updateBikeDropDown(){
      bikeDropKey.value++;
    }

    function updateWheelchairDropDown(){
      wheelchairDropKey.value++;
    }

    const searchErrorMessage = computed(() => {
      return Store.state.errorReason;
    });

    const hasSearchError = computed(() => {
      return Store.state.hasSearchError;
    });

    //called on emit from radio - indicating that additional storage is required.
    function assignAdditionalRequired(onOff : boolean) {
      //updateKey.value++;
      Store.appSwitches.needsAdditionalStorage = onOff;
     }
    //const router = useRouter();

    const nextStep = async () => {

      // //Change SSR to new Bikes assigned in progress
      // if(ssrListDisabled.value){
      //   Store.state.ssrTotals = undefined;
      //   storeSsr = [];
      // }

      // //Iterate through BikeSSR and set up extras
      // if(Store.state.ssrSplits){
      //   Store.state.ssrSplits?.forEach( ssr => {
      //     if(ssr.quantity > 0){
      //       storeSsr.push({
      //         passanger: ssr.passanger,
      //         ssrType: ssr.ssrType,
      //         ticketType: ssr.ticketType,
      //         quantity: ssr.quantity
      //       });
      //     }
          
      //   });
      // }
      


      //Build search URL - adjust for testing on localhost
      let start = Store.getUrl();
      if (start == '')  //localhost
        start = 'https://localhost:44346';
      let theUrl = start+'/#/widget-search?';
      theUrl += 'o='+Store.state.origin.code;
      theUrl += '&d='+Store.state.destination.code;
      theUrl += '&od='+Store.state.departure.date;
      theUrl += '&ot='+Store.state.departure.time;
      if (Store.state.departure.date == '') {
        theUrl += '&jt=SINGLE'
      }
      else {
        theUrl += '&jt=RETURN';
        theUrl += '&rd='+Store.state.return.date;
        theUrl += '&rt='+Store.state.return.time;
      }
      //array of passengers
      theUrl += '&p=[{';
      let cnt = 0;
      Store.state.passengers?.forEach((pass) => {
        if (pass.quantity > 0) {
          if (cnt == 0)
            theUrl += '"fareId":"'+pass.fareClass+'","count":'+pass.quantity+'}' 
          else
            theUrl += ',{"fareId":"'+pass.fareClass+'","count":'+pass.quantity+'}';
          cnt ++;
        }
      });
      theUrl += ']';
      //wheelchairs
      if (Store.state.wheelchairRequired) {
        theUrl += '&w=YES'
        theUrl += '&wc='+Store.state.wheelchairFareclass;
      }
      else {
        theUrl += '&w=NO';
        theUrl += '&wc=na';
      }

      // theUrl += '&ssrE=[';
      // let cnt2 = 0;
      // //Bike SSRExtras
      // storeSsr?.forEach((ssr) => {
      //   if (ssr.quantity > 0) {
      //     if (cnt2 == 0)
      //       theUrl += '{"passanger":'+ssr.passanger+',"quantity":'+ssr.quantity+',"ticketType":"'+ssr.ticketType+'","ssrType":'+ssr.ssrType+'}';
      //     else
      //       theUrl += ',{"passanger":'+ssr.passanger+',"quantity":'+ssr.quantity+',"ticketType":"'+ssr.ticketType+'","ssrType":'+ssr.ssrType+'}';
      //     cnt2 ++;
      //   }
      // });
      // theUrl += ']';

      // theUrl += '&ssrS=[';
      // let cnt3 = 0;
      // //Bike Splits
      // Store.state.ssrSplits?.forEach((ssr) => {
      //     if (cnt3 == 0)
      //       theUrl += '{"description":"'+ssr.description+'","passanger":'+ssr.passanger+',"quantity":'+ssr.quantity+',"quantityMax":'+ssr.quantityMax+',"ticketType":"'+ssr.ticketType+'","ssrType":'+ssr.ssrType+'}';
      //     else
      //       theUrl += ',{"description":"'+ssr.description+'","passanger":'+ssr.passanger+',"quantity":'+ssr.quantity+',"quantityMax":'+ssr.quantityMax+',"ticketType":"'+ssr.ticketType+'","ssrType":'+ssr.ssrType+'}';
      //     cnt3 ++;
      // });
      // theUrl += ']';


      // //Bike SSRTotals
      // theUrl += '&ssrT=[';

      // Store.state.ssrTotals?.forEach(ssrTotal => {
      //   theUrl += '{"fareLineId":"'+ssrTotal.fareLineId+'","ssrType":"'+ssrTotal.ssrType+'","ssrCount":'+ssrTotal.ssrCount+'}';
      // });

      // theUrl += ']';

      //Extras
      //New flow - if user needs luggage, show luggage screen, otherwise do the search
      if (Store.appSwitches.needsAdditionalStorage) {
        // //Turn on header sub-bar
        // Store.appSwitches.showHeaderSubBar = true;
        // //Set the text on the right button and retain search if go back used
        // Store.appSwitches.buyMoreTicketsText='Change Your Journey';
        // Store.appSwitches.willClearSearchParameters = false;
        theUrl += '&as=YES'

       //create extras array - also create SSR extras array which is now populated the same as luggage was - EK

        if (Store.state.passengers) {
          Store.state.passengers.forEach((element) => {
            if (element.quantity) {
              for (let i = 0; i < element.quantity; i++) {
                const length = storeExtras.length;
                storeExtras.push({
                  passanger: length + 1,
                  ticketType: element.fareClass,
                  quantity: 0,
                  ssrType: SsrType.Luggage,
                });
                //ek new
                storeSSRExtras.push({
                  passanger: length + 1,
                  ticketType: element.fareClass,
                  quantity: 0,
                  ssrType: SsrType.BICYCLE,
                });
              }         
            }
          });

          //Send Extras Array to frontend
          theUrl += '&E=[';
          let cnt2 = 0;
          storeExtras?.forEach((extra) => {
            if (cnt2 == 0){
              theUrl += '{"passanger":'+extra.passanger+',"quantity":'+extra.quantity+',"ticketType":"'+extra.ticketType+'","ssrType":'+extra.ssrType+'}';
              cnt2 ++;
            }else{
              theUrl += ',{"passanger":'+extra.passanger+',"quantity":'+extra.quantity+',"ticketType":"'+extra.ticketType+'","ssrType":'+extra.ssrType+'}';
              cnt2 ++;
            }
          });
          theUrl += ']';

          //Send ssrExtras Array to frontend
          theUrl += '&ssrE=[';
          let cnt3 = 0;
          storeSSRExtras?.forEach((ssrExtra) => {
            if (cnt3 == 0){
              theUrl += '{"passanger":'+ssrExtra.passanger+',"quantity":'+ssrExtra.quantity+',"ticketType":"'+ssrExtra.ticketType+'","ssrType":'+ssrExtra.ssrType+'}';
              cnt3 ++;
            }else{
              theUrl += ',{"passanger":'+ssrExtra.passanger+',"quantity":'+ssrExtra.quantity+',"ticketType":"'+ssrExtra.ticketType+'","ssrType":'+ssrExtra.ssrType+'}';
              cnt3 ++;
            }
          });
          theUrl += ']';


        }

      }else{
        theUrl += '&as=NO'
      }

       
      window.location.href = theUrl;

    }

    function setProgress() {
      let countProgress = 0;
      if (Store.state.origin.code !== '' && !!Store.state.origin.code) {
        countProgress += 25;
      }

      if (Store.state.destination.code !== '') countProgress += 25;

      if (Store.state.departure.date !== '') countProgress += 25;

      let passengersComplete = false;
      if (Store.state.passengers != undefined) {
         Store.state.passengers.forEach((pass) => {
          if (pass.quantity > 0)
            passengersComplete = true;
        })
        if (passengersComplete) countProgress += 25;
      }

      if (Store.state.origin.code !== '' && Store.state.destination.code !== '') {
        if (Store.state.origin.code == Store.state.destination.code) {
          Store.state.hasSearchError = true;
          Store.state.errorReason = "Origin and Destination cannot be the same";
        } else {
          Store.state.hasSearchError = false;
        }

        let hasPassangers;
        if (Store.state.passengers !== undefined) {
          hasPassangers = Store.state.passengers.find(
          (element) => element.quantity > 0
        );  
        }

        //validation for wheelchair select display
        if((Store.state.origin.ssrs[0] == Store.state.destination.ssrs[0]) && hasPassangers){
          wheelchairListDisabled.value = false;
        }else{
          wheelchairListDisabled.value = true;
        }
      } else {
        Store.state.hasSearchError = false;
      }

      //Check if there are too many bikes
      if (Store.state.passengers !== undefined) {
        const hasPassangers = Store.state.passengers.find(
          (element) => element.quantity > 0
        );
        if(Store.state.ssrTotals !== undefined){
          const bikeQuantity = Store.state.ssrTotals.find(
          (element) => element.ssrType == "BICYCLE"
        );

        if(bikeQuantity !== undefined && hasPassangers !== undefined){
          
          var bikeQuantityError = false;
          
          Store.state.passengers?.forEach(pass => {
            if(pass.quantity > 0){
            Store.state.ssrSplits?.forEach(ssr => {
              if((pass.fareClass == ssr.ticketType) && ssr.ssrType == SsrType.BICYCLE && pass.quantity < ssr.quantity){
                bikeQuantityError = true;
              }
            })  
              
            }
          });



          if((bikeQuantityError) && !ssrListDisabled.value){
            Store.state.hasSearchError = true;
            Store.state.errorReason = "Bike quantity cannot be greater than the number of passenger's";
            findButtonDisabled.value = true;
          }else{
            findButtonDisabled.value = false;
            Store.state.hasSearchError = false;
          }
        }
        }
      }

      //compute whether find button is disabled for wheelchair class not selected
      if (Store.state.wheelchairRequired && hasMultiplePassengerGroups() && (Store.state.wheelchairFareclass == '' || Store.state.wheelchairFareclass == undefined)) {
        findButtonDisabled.value = true;
      }
      else {
        findButtonDisabled.value = false;
      }
      if (countProgress > 99) 
        isValidSearch.value = true
      else
        isValidSearch.value = false;
      return countProgress;
    }

    function switchDirection() {
      let holder = Store.state.origin;
      Store.state.origin = Store.state.destination;
      Store.state.destination = holder;
      if (origin.value != null)
        (origin.value as any).setStop(Store.state.origin);
      if (destination.value != null)
        (destination.value as any).setStop(Store.state.destination);
    }

    return {
      nextStep,
      isValidSearch,
      isScreenDirty,
      Progress,
      searchErrorMessage,
      hasSearchError,
      clearItems,
      updateKey,
      updateKey2,
      updateKey3,
      assignWheelchair,
      showSelectClass,
      wheelchairClassSelected,
      wheelchairRequired,
      findButtonDisabled,
      clearReturn,
      wheelchairRadioValue,
      switchDirection,
      theStore,
      openComponent,
      showSecondLine,
      forceIt,
      setProgress,
      origin,
      destination,
      ssrListDisabled,
      wheelchairListDisabled,
      bikeDropKey,
      updateBikeDropDown,
      additionalRadioValue,
      updateWheelchairDropDown,
      wheelchairDropKey,
      assignAdditionalRequired,
      findButtonText,
      isSearching,
      numberOfPassTypes
     };
  },

});
